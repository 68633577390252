const locationLoadedEvent = new CustomEvent("locationLoaded", {});

mapboxgl.accessToken = "pk.eyJ1IjoiZm9nb2NydXphZG8iLCJhIjoiY2xlbmNicXE1MDN3bDN5bWg3MHVkOHdlZCJ9.KLnBRniUM1mXT6boddOXRw";
const geocoder = new MapboxGeocoder({
  accessToken: mapboxgl.accessToken,
  bbox: [
    -44.00, // S (minY)
    -23.5, // W (minX)
    -42.50,  // N (maxY)
    -22.50 // E (maxX)
  ],
  placeholder: "Avenida Brasil"
});

geocoder.addTo("#geocoder");

// Get the geocoder results container.
const results = document.getElementById("result");

async function processResult(result) {
  const data = window.ops;
  let closest;
  let closest_distance = 0;
  let distance;

  data.forEach((op) => {
    distance = haversine(result.center, [op.lon, op.lat]);

    if (distance < closest_distance || closest_distance === 0) {
      closest = op;
      closest_distance = distance;
    }
  });

  const image = await getPathImage([closest.lon, closest.lat], result.center);

  updateBox(closest, closest_distance, image);
}
// Add geocoder result to container.
geocoder.on("result", (e) => processResult(e.result));

async function getPathImage(dest, orig) {
  return await fetch(`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-a+000(${orig[0]},${orig[1]}),pin-s-b+9ed4bd(${dest[0]},${dest[1]})/auto/500x300?padding=50,50,50&access_token=${mapboxgl.accessToken}`);
}

function updateBox(obj, dist, img) {
  const template = document.getElementById("template").innerHTML;
  const tourStep = document.querySelector(".tour-1");
  let unit = "km";
  dist = Math.ceil(dist);

  if (dist < 1000) {
    unit = "metros";
  } else {
    dist = Math.ceil(dist / 1000);
  }

  tourStep.innerHTML = template
    .replace("%%BAIRRO%%", obj.bairro)
    .replace("%%DISTANCIA%%", `${dist} ${unit}`)
    .replace("%%CHACINA%%", obj.localidade)
    .replace("%%DATA%%", `${obj.dia}/${obj.mes}/${obj.ano}`)
    .replace("%%MORTOS%%", obj.mortos);

  tourStep.innerHTML += `<div class="path"><img src="${img.url}" alt="Um mapa contendo dois pins, um mostrando a localização do usuário no bairro ${obj.bairro} e outro mostrando o local da chacina ${obj.localidade}."/></div>`;

  document.dispatchEvent(locationLoadedEvent);
}

function haversine(orig, dest) {
  let lon1, lat1, lon2, lat2;
  [lon1, lat1] = orig;
  [lon2, lat2] = dest;
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
}

const usarLocalizacao = document.querySelector(".use");
usarLocalizacao.addEventListener("click", (e) => {
  console.log(e);
  navigator.geolocation.getCurrentPosition((pos) => {
    const position = { center: [pos.coords.longitude, pos.coords.latitude] };
    processResult(position);
  });
});